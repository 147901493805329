




































import Vue from 'vue';
import CreateMembershipCorrespondenceContactModel from '../../../../../services/viewModel/resource/CorrespondenceAddressResourceViewModel';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    memberCorrespondenceContact: {
      set(value: any) {
        this.innerValue.memberCorrespondenceContact = value;
      },
      get(): CreateMembershipCorrespondenceContactModel {
        return this.innerValue.memberCorrespondenceContact ?? {};
      },
    },
    languageItems(): {caption: string, id: string}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.german'),
          id: 'DE',
        },
        {
          caption: this.$t('page.createMembership.select.french'),
          id: 'FR',
        },
        {
          caption: this.$t('page.createMembership.select.italian'),
          id: 'IT',
        },
      ];
    },
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (!this.memberCorrespondenceContact.languageIso2) {
      this.$set(this.memberCorrespondenceContact, 'languageIso2', this.$translation.get().toUpperCase());
    }
  },
});
